import React, { useContext, useState } from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { UserContext } from "./UserContext";
import useFetchAllClasses from "./useFetchAllClasses";
import { firestore } from "./FirebaseConfig";
import { useNavigate, Link } from "react-router-dom";
import InviteModal from "./InviteModal";
import "./DisplayStudents.css";

const DisplayClasses = () => {
  const { user, schoolId } = useContext(UserContext);
  const { classList, loading } = useFetchAllClasses(schoolId);
  const navigate = useNavigate();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [selectedClassData, setSelectedClassData] = useState(null);

  const handleInviteTeachers = (classData) => {
    setSelectedClassData(classData);
    setIsInviteModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setIsInviteModalOpen(false);
    setSelectedClassData(null);
  };

  const handleInviteSubmit = () => {
    setIsInviteModalOpen(false);
  };

  const handleDeleteClass = async (classId) => {
    try {
      const userRef = doc(firestore, "users", user.uid);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        const classesData = userSnapshot.data()?.activeClasses || [];
        const updatedClassesData = classesData.filter(
          (classData) => classData.id !== classId
        );

        await updateDoc(userRef, {
          activeClasses: updatedClassesData,
        });

        console.log("Class deleted successfully!");
      } else {
        console.error("User document not found.");
      }
    } catch (error) {
      console.error("Error deleting class:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="display-students-container">
      <h2>Classes</h2>
      <Link to="/addclass">
        <button className="blue-buttons">Add Class</button>
      </Link>

      {classList.length === 0 ? (
        <p>No classes found.</p>
      ) : (
        <ul>
          {classList.map((classData) => (
            <li key={classData.id}>
              <strong>{classData.className}</strong> - {classData.schoolYear} -
              Grade: {classData.gradeLevel} -
              {classData.enrolledStudents?.length || 0} students
              <div className="class-actions">
                <button
                  className="blue-buttons"
                  onClick={() => handleInviteTeachers(classData)}
                >
                  Invite Teachers
                </button>
                <Link to={`/addstudent/${classData.id}`}>
                  <button className="blue-buttons">Add Students</button>
                </Link>
                <button
                  className="blue-buttons"
                  onClick={() => handleDeleteClass(classData.id)}
                  disabled={classData.students?.length > 0}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {isInviteModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <InviteModal
              onClose={handleCloseInviteModal}
              user={user}
              classData={selectedClassData}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplayClasses;
