import React, { useState, useRef } from "react";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  setDoc,
} from "firebase/firestore";
import { firestore, serverTimestamp } from "./FirebaseConfig";
import useFetchClasses from "./useFetchClasses";
import useFilteredStudents from "./useFilteredStudents";

import "./Attendence.css"; // Import your custom CSS file

const Attendance = () => {
  const [selectedClassId, setSelectedClassId] = useState("");
  const [studentIdInput, setStudentIdInput] = useState(""); // New state for student ID input
  const { classList } = useFetchClasses();
  const { filteredStudents, refreshFilteredStudents } =
    useFilteredStudents(selectedClassId);
  const studentIdInputRef = useRef();
  const isDisabled = false; // Replace `false` with the appropriate value

  console.log("filered students: ", filteredStudents);
  console.log("Set Student ID Input: ", studentIdInput);

  const handleClassChange = (event) => {
    const selectedId = event.target.value;
    setSelectedClassId(selectedId);
  };

  const markAttendance = async (sid) => {
    try {
      if (!selectedClassId) {
        console.log("No class selected for attendance marking.");
        return;
      }

      const classRef = doc(firestore, "classes", selectedClassId);
      const attendanceCollectionRef = collection(classRef, "attendance");

      const currentDate = new Date().toISOString().slice(0, 10);
      const attendanceDocRef = doc(
        attendanceCollectionRef,
        currentDate // Use current date as the document ID
      );

      const currentTimestamp = serverTimestamp();

      // Update the attendance data structure
      const attendanceData = {
        timestamp: currentTimestamp,
        sid: sid, // Use the student's ID field
      };

      // Create a subcollection for each student
      const studentAttendanceCollectionRef = collection(
        attendanceDocRef,
        "students"
      );

      // Check if the student attendance document exists
      const studentAttendanceDocRef = doc(studentAttendanceCollectionRef, sid);

      if (studentAttendanceDocRef) {
        // Create a document for the student's attendance data
        await setDoc(studentAttendanceDocRef, attendanceData, { merge: true });
        refreshFilteredStudents();

        console.log("Attendance marked for student (sid):", sid);
      } else {
        console.error("Student attendance document doesn't exist:", sid);
      }
    } catch (error) {
      console.error("Error marking attendance:", error);
    }
  };

  // Function to handle manual student ID input
  const handleStudentIdInput = (event) => {
    setStudentIdInput(event.target.value);
  };

  const getSidByStudentId = (studentId) => {
    const foundStudent = filteredStudents.find(
      (student) => student.studentId === studentId
    );
    return foundStudent ? foundStudent.id : null;
  };

  // Function to handle marking attendance using student ID input
  const handleMarkByStudentId = async () => {
    try {
      if (!selectedClassId) {
        console.log("No class selected for attendance marking.");
        return;
      }

      const studentsCollectionRef = collection(firestore, "students");
      const q = query(
        studentsCollectionRef,
        where("studentId", "==", studentIdInput.trim())
      );
      console.log("Hitting the try in the Marked ID");

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No student found with the given student ID.");
        return;
      }

      const studentDoc = querySnapshot.docs[0];
      const sid = studentDoc.id;
      console.log("Studnet Doc: ", studentDoc);
      console.log("Studnet Id: ", sid);

      if (sid) {
        markAttendance(sid);
        setStudentIdInput(""); // Clear the input after marking attendance
        // Focus the input element again after attendance is marked
        studentIdInputRef.current.focus();
      } else {
        console.log("Student not found in the filtered list.");
      }
    } catch (error) {
      console.error("Error marking attendance:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleMarkByStudentId();
    }
  };

  return (
    <div className="attendance-container">
      <h2>Mark Attendance for Today</h2>
      <div>
        <label>Select Class:</label>
        <select className="class-select" onChange={handleClassChange}>
          <option value="">Select a class</option>
          {classList.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.className}
            </option>
          ))}
        </select>
      </div>
      {isDisabled && (
        <div className="manual-attendance">
          <label>Enter Student ID:</label>
          <input
            type="text"
            value={studentIdInput}
            onChange={handleStudentIdInput}
            onKeyPress={handleKeyPress} // Attach the event handler for "Enter" key press
            ref={studentIdInputRef} // Attach the ref to the input element
          />
          <button className="blue-buttons" onClick={handleMarkByStudentId}>
            Mark Attended
          </button>
        </div>
      )}

      <div className="student-grid">
        {filteredStudents.map((student) => (
          <div
            key={student.id}
            className="student-item"
            onClick={() => markAttendance(student.id)} // Add onClick event handler
          >
            <span className="student-name">{student.studentName}</span>
            {/* Remove the button */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Attendance;
