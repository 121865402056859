import React, { useState } from "react";
import { firestore } from "./FirebaseConfig";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import "./modal.css";

const InviteModal = ({ onClose, user, classData }) => {
  const [invitedEmail, setInvitedEmail] = useState("");
  const [teacherRole, setTeacherRole] = useState(""); // New state for teacher role
  console.log("Modal Class Data: ", classData);
  console.log("ClassName: ", classData.className);

  const handleInviteSubmit = async (e) => {
    e.preventDefault();

    try {
      const invitationsCollectionRef = collection(firestore, "invitations");
      const newInvitation = {
        invitedByEmail: user.email, // Using the passed user prop
        invitedToEmail: invitedEmail,
        classId: classData.id, // Using the passed classData prop
        className: classData.className, // Include the class name
        teacherRole: teacherRole, // Include the teacher role
        createdAt: serverTimestamp(),
        status: "pending",
      };

      await addDoc(invitationsCollectionRef, newInvitation);
      onClose(); // Close the modal after successful invitation
    } catch (error) {
      console.error("Error inviting teacher:", error);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Invite Teacher to Join {classData.className}</h2>{" "}
        {/* Display class name */}
        <div className="form-row">
          <label>Email:</label>
          <input
            type="email"
            value={invitedEmail}
            onChange={(e) => setInvitedEmail(e.target.value)}
          />
        </div>
        {/* Dropdown for teacher role */}
        <div className="form-row">
          <label>Teacher Role:</label>
          <select
            className="teacher-role-dropdown" // Add this class
            value={teacherRole}
            onChange={(e) => setTeacherRole(e.target.value)}
          >
            <option value="">Select Role</option>
            <option value="co-teacher">Co-Teacher</option>
            <option value="other">Other</option>
          </select>
        </div>
        <button className="blue-buttons" onClick={handleInviteSubmit}>
          Invite
        </button>
        <button className="blue-buttons" onClick={handleClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default InviteModal;
