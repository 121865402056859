//retrieves a list of students enrolled in a specified class, excluding those who have attended class on the current date

import { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "./FirebaseConfig"; // Import the Firestore instance

const useFilteredStudents = (classId) => {
  const [filteredStudents, setFilteredStudents] = useState([]);

  const fetchFilteredStudents = async () => {
    try {
      if (!classId) {
        console.log("Invalid class ID. Skipping fetchFilteredStudents.");
        return;
      }

      const currentDate = new Date().toISOString().slice(0, 10);
      const classRef = doc(firestore, "classes", classId);
      const attendanceCollectionRef = collection(classRef, "attendance");
      const attendanceDocRef = doc(attendanceCollectionRef, currentDate);
      const studentAttendanceCollectionRef = collection(
        attendanceDocRef,
        "students"
      );

      // Get the attended student IDs for the current date
      const attendedSnapshot = await getDocs(studentAttendanceCollectionRef);
      const attendedStudentIds = attendedSnapshot.docs.map((doc) => doc.id);

      // Fetch all students enrolled in the class
      const studentsCollectionRef = collection(firestore, "students");
      const q = query(
        studentsCollectionRef,
        where("enrolledClasses", "array-contains", classId)
      );
      const studentsSnapshot = await getDocs(q);

      // Filter out students who are marked as attended for the current date
      const filteredStudentsData = studentsSnapshot.docs
        .filter((doc) => !attendedStudentIds.includes(doc.id))
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

      setFilteredStudents(filteredStudentsData);
    } catch (error) {
      console.error("Error fetching filtered students:", error);
      setFilteredStudents([]); // Reset students to an empty array in case of an error.
    }
  };

  useEffect(() => {
    fetchFilteredStudents();
  }, [classId]);

  const refreshFilteredStudents = () => {
    fetchFilteredStudents(); // Make sure to call the function here
  };

  return { filteredStudents, refreshFilteredStudents };
};

export default useFilteredStudents;
