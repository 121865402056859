import React from "react";

function Home() {
  return (
    <div>
      <h3 style={{ marginTop: "150px" }}>Welcome to ClassBOON!</h3>
    </div>
  );
}

export default Home;
