import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  arrayContains,
} from "firebase/firestore";
import { firestore } from "./FirebaseConfig"; // Import the Firestore instance

const useFetchStudents = (classId) => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        if (!classId) {
          console.log("Invalid class ID. Skipping fetchStudents.");
          return;
        }

        console.log("Fetching students...");
        const studentsCollectionRef = collection(firestore, "students");
        const q = query(
          studentsCollectionRef,
          where("enrolledClasses", "array-contains", classId)
        );
        const querySnapshot = await getDocs(q);

        const studentsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("Fetched students data:", studentsData); // Log the fetched students data
        setStudents(studentsData);
      } catch (error) {
        console.error("Error fetching students:", error);
        setStudents([]); // Reset students to an empty array in case of an error.
      }
    };

    fetchStudents();
  }, [classId]);

  return { students, setStudents };
};

export default useFetchStudents;
