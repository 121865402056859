import React, { useContext } from "react";
import { UserContext } from "./UserContext";
import DisplayClasses from "./DisplayClasses";
import useFetchClasses from "./useFetchClasses";
import Invitations from "./Invitations";

const Dashboard = () => {
  const { user } = useContext(UserContext); // Destructure the user object from the context
  const { classList } = useFetchClasses(); // Fetch the class list using useFetchClasses

  const welcomeMessage = () => {
    if (user) {
      const { displayName } = user;
      return `Welcome, ${displayName}!`;
    } else {
      return "Loading user data...";
    }
  };

  return (
    <div>
      <h2>Dashboard</h2>
      <h1>{welcomeMessage()}</h1>

      {/* Display the current classes */}
      <DisplayClasses user={user} />
      <Invitations user={user} />
    </div>
  );
};

export default Dashboard;
