import { useState, useEffect } from "react";
import { doc, collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";

const useFilteredEmergencyStudents = (classId) => {
  const [notAccountedForStudents, setNotAccountedForStudents] = useState([]);
  const [accountedForStudents, setAccountedForStudents] = useState([]);

  const fetchFilteredEmergencyStudents = async () => {
    try {
      if (!classId) {
        console.log(
          "Invalid class ID. Skipping fetchFilteredEmergencyStudents."
        );
        return;
      }

      const currentDate = new Date().toISOString().slice(0, 10);
      const classRef = doc(firestore, "classes", classId);
      const emergencyCollectionRef = collection(classRef, "emergencies");
      const emergencyDocRef = doc(emergencyCollectionRef, currentDate);
      const studentEmergencyCollectionRef = collection(
        emergencyDocRef,
        "students"
      );

      const attendedSnapshot = await getDocs(studentEmergencyCollectionRef);
      const attendedStudents = attendedSnapshot.docs.map((doc) => ({
        id: doc.id,
        clickedBy: doc.data().clickedBy,
        timestamp: doc.data().timestamp.toDate(), // Convert Firestore timestamp to JavaScript Date object
      }));

      const studentsCollectionRef = collection(firestore, "students");
      const q = query(
        studentsCollectionRef,
        where("enrolledClasses", "array-contains", classId)
      );
      const studentsSnapshot = await getDocs(q);

      const allStudents = studentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const notAccountedForStudentsData = allStudents.filter(
        (student) =>
          !attendedStudents.some((attended) => attended.id === student.id)
      );

      const accountedForStudentsData = allStudents
        .filter((student) =>
          attendedStudents.some((attended) => attended.id === student.id)
        )
        .map((student) => {
          const matchingEmergency = attendedStudents.find(
            (attended) => attended.id === student.id
          );

          return {
            id: student.id,
            ...student,
            timestamp: matchingEmergency.timestamp,
            clickedBy: matchingEmergency.clickedBy,
          };
        });

      setNotAccountedForStudents(notAccountedForStudentsData);
      setAccountedForStudents(accountedForStudentsData);
    } catch (error) {
      console.error("Error fetching filtered emergency students:", error);
      setNotAccountedForStudents([]); // Reset students to an empty array in case of an error.
      setAccountedForStudents([]);
    }
  };

  useEffect(() => {
    fetchFilteredEmergencyStudents();
  }, [classId]);

  const refreshFilteredEmergencyStudents = () => {
    fetchFilteredEmergencyStudents();
  };

  return {
    notAccountedForStudents,
    accountedForStudents,
    refreshFilteredEmergencyStudents,
  };
};

export default useFilteredEmergencyStudents;
