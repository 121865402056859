import { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";

const useEnrolledStudents = () => {
  const [enrolledStudentsMap, setEnrolledStudentsMap] = useState({});
  const [studentNamesMap, setStudentNamesMap] = useState({});
  const [studentIdMap, setStudentIdMap] = useState({});
  const [studentGradeMap, setStudentGradeMap] = useState({}); // New state for student grades

  useEffect(() => {
    const fetchEnrolledStudents = async () => {
      try {
        // Fetch all student documents
        const studentsQuerySnapshot = await getDocs(
          collection(firestore, "students")
        );

        // Process student documents and create mappings
        const enrolledMapping = {};
        const namesMapping = {};
        const idMapping = {};
        const gradeMapping = {}; // New mapping for grades

        studentsQuerySnapshot.forEach((studentDoc) => {
          const studentData = studentDoc.data();
          const studentId = studentDoc.id;

          enrolledMapping[studentId] = studentData.enrolledClasses;
          namesMapping[studentId] = studentData.studentName;
          idMapping[studentId] = studentData.studentId;
          gradeMapping[studentId] = studentData.grade; // Add grade to the mapping
        });

        setEnrolledStudentsMap(enrolledMapping);
        setStudentNamesMap(namesMapping);
        setStudentIdMap(idMapping);
        setStudentGradeMap(gradeMapping); // Set the grade mapping
      } catch (error) {
        // Handle error
      }
    };

    fetchEnrolledStudents();
  }, []);

  return {
    enrolledStudentsMap,
    studentNamesMap,
    studentIdMap,
    studentGradeMap,
  }; // Return the grade mapping
};

export default useEnrolledStudents;
