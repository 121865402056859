import React, { useEffect, useState, useContext } from "react";
import {
  ref,
  query,
  orderByChild,
  equalTo,
  onValue,
  off,
} from "firebase/database";
import { db } from "./FirebaseConfig";
import { UserContext } from "./UserContext";
import DatePicker from "react-datepicker"; // Import the DatePicker component
import "react-datepicker/dist/react-datepicker.css"; // Import the DatePicker styles
import "./DisplaySignOut.css";

function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return date.toLocaleDateString(undefined, options);
}

function calculateTimeDifference(signOutTime, signInTime) {
  if (!signOutTime || !signInTime) {
    return "N/A";
  }

  const signOutTimestamp = new Date(signOutTime).getTime();
  const signInTimestamp = new Date(signInTime).getTime();

  const timeDifference = signInTimestamp - signOutTimestamp;
  const minutes = Math.floor(timeDifference / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return `${minutes}m ${seconds}s`;
}

function formatTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

function SignOutsTeacherRecord() {
  const user = useContext(UserContext);
  const [signOutData, setSignOutData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSignOutData, setFilteredSignOutData] = useState([]);
  const reversedFilteredSignOutData = [...filteredSignOutData].reverse();
  console.log("USer Stuff: ", user);

  useEffect(() => {
    const signOutsRef = ref(db, "signOuts");

    const queryRef = query(
      signOutsRef,
      orderByChild("teacherUID"),
      equalTo(user.uid)
    );

    const handleSnapshot = (snapshot) => {
      const data = [];
      snapshot.forEach((childSnapshot) => {
        const signOut = { id: childSnapshot.key, ...childSnapshot.val() };
        data.push(signOut);
      });
      setSignOutData(data);
    };

    const errorCallback = (error) => {
      console.error("Error retrieving sign-out data:", error);
    };

    onValue(queryRef, handleSnapshot, { errorCallback });

    return () => {
      off(queryRef, handleSnapshot);
    };
  }, [user.uid]);

  useEffect(() => {
    // Filter the sign-out data based on the search term
    const filteredData = signOutData.filter(
      (signOut) =>
        signOut.studentName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        signOut.idNumber.includes(searchTerm) ||
        signOut.location.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSignOutData(filteredData);
  }, [signOutData, searchTerm]);

  return (
    <div className="table-container">
      <h1>{user.displayName}'s SignOut Record</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name, student ID, or location"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Grade</th>
            <th>Name</th>
            <th>Location</th>
            <th>Time Out</th>
            <th>Time In</th>
            <th>Time Difference</th>
          </tr>
        </thead>
        <tbody>
          {reversedFilteredSignOutData.map((signOut) => (
            <tr key={signOut.id}>
              <td>{formatDate(signOut.time_out)}</td>
              <td>{signOut.grade}</td>
              <td>{signOut.studentName}</td>
              <td>{signOut.location}</td>
              <td>{formatTime(signOut.time_out)}</td>
              <td>
                {signOut.time_in
                  ? formatTime(signOut.time_in)
                  : "Not signed in"}
              </td>
              <td>
                {signOut.time_in
                  ? calculateTimeDifference(signOut.time_out, signOut.time_in)
                  : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SignOutsTeacherRecord;
