// AfterRegistrationSchoolSelection.js
import React, { useState, useContext } from "react";
import SchoolDropdown from "./SchoolDropdown";
import AddSchoolModal from "./AddSchoolModal";
import { firestore, auth } from "./FirebaseConfig";
import { collection, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { useNavigate } from "react-router-dom"; // Import the Link component

const AfterRegistrationSchoolSelection = () => {
  const userUid = auth.currentUser.uid;
  const navigate = useNavigate();

  console.log("userUID: ", userUid);

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [showAddSchoolModal, setShowAddSchoolModal] = useState(false);
  console.log("User UID: ", userUid);

  const handleAddSchool = () => {
    setShowAddSchoolModal(true); // Open the modal
  };

  const handleSubmitSchool = async () => {
    console.log("Selected School: ", selectedSchool);
    console.log("User ID and School ID: ", userUid, selectedSchool.id);

    if (userUid && selectedSchool && selectedSchool.id) {
      try {
        // Update the user's collection with the selected school ID
        const userDocRef = doc(firestore, "users", userUid);
        await updateDoc(userDocRef, {
          schoolId: selectedSchool.id,
          schoolName: selectedSchool.name, // Add the school name
        });

        // Update the school's collection with the teacher's UID
        const schoolDocRef = doc(firestore, "schools", selectedSchool.id);
        await updateDoc(schoolDocRef, {
          teachers: arrayUnion(userUid),
        });

        // Perform additional actions if needed
      } catch (error) {
        console.error("Error updating documents:", error);
      }
      navigate("/dashboard");
    }
  };

  return (
    <div>
      <h2>Select your School</h2>

      {/* Display the searchable school dropdown */}
      <SchoolDropdown
        onAddSchool={handleAddSchool}
        onSelectSchool={setSelectedSchool} // Pass setSelectedSchool as the callback
      />

      {/* Display the selected school or school data */}
      {selectedSchool && (
        <div>
          {/* Display the selected school details */}
          <p>You have selected the following school:</p>
          <p>{selectedSchool.name}</p>
          <p>{selectedSchool.address}</p>
          <p>
            {selectedSchool.city}, {selectedSchool.state} {selectedSchool.zip}
          </p>

          <button onClick={handleSubmitSchool}>Submit School</button>
        </div>
      )}

      {/* Conditionally render the modal */}
      {showAddSchoolModal && (
        <AddSchoolModal
          onClose={() => setShowAddSchoolModal(false)}
          onSchoolAdded={(newSchoolData) => {
            setSelectedSchool(newSchoolData);
            setShowAddSchoolModal(false);
          }}
        />
      )}
    </div>
  );
};

export default AfterRegistrationSchoolSelection;
