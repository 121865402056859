import React, { useState, useContext, useEffect } from "react";
import useFullSchoolList from "./useFullSchoolList";
import StudentList from "./StudentList";
import EnrolledClassesFilter from "./EnrolledClassesFilter";
import useEnrolledClassesDropdown from "./useEnrolledClassesDropdown";
import {
  doc,
  collection,
  setDoc,
  getDocs,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { firestore, serverTimestamp } from "./FirebaseConfig";
import { UserContext, useUser } from "./UserContext";
import "./Emergency.css";

const SchoolList = () => {
  const { fullSchoolList } = useFullSchoolList();
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [selectedClass, setSelectedClass] = useState("");
  const { schoolId } = useContext(UserContext);
  const { user } = useUser();

  const [unaccountedStudents, setUnaccountedStudents] = useState([]);
  const [accountedForStudents, setAccountedForStudents] = useState([]);
  const { enrolledClasses } = useEnrolledClassesDropdown();

  useEffect(() => {
    fetchAndHandleLatestStudentData();

    const currentDate = new Date().toISOString().slice(0, 10);
    const emergenciesQuery = collection(
      firestore,
      "schools",
      schoolId,
      "emergencies",
      currentDate,
      "students"
    );

    const unsubscribeFromEmergencies = onSnapshot(
      emergenciesQuery,
      handleSnapshotChanges,
      (error) => {
        console.error("Error with onSnapshot:", error);
      }
    );

    return () => {
      unsubscribeFromEmergencies();
    };
  }, [schoolId]);

  const fetchAndHandleLatestStudentData = async () => {
    try {
      console.log("Fetching and handling latest student data...");
      const updatedStudents = await fetchLatestStudentData();
      console.log("Fetched and handled latest student data:", updatedStudents);
      updateStudentList(updatedStudents);
    } catch (error) {
      console.error("Error fetching and handling latest student data:", error);
    }
  };

  const handleSnapshotChanges = (snapshot) => {
    console.log(
      "Snapshot changes in emergencies:",
      snapshot.docs.map((doc) => doc.data())
    );
    fetchAndHandleLatestStudentData();
  };

  const fetchLatestStudentData = async () => {
    console.log("Fetching latest student data...");
    const schoolRef = doc(firestore, "schools", schoolId);
    const studentsCollectionRef = collection(schoolRef, "students");
    const studentsSnapshot = await getDocs(studentsCollectionRef);

    console.log("studentsSnapshot:", studentsSnapshot);

    const updatedStudents = studentsSnapshot.docs.map((doc) => doc.data());
    console.log("Fetched latest student data:", updatedStudents);
    return updatedStudents;
  };

  const updateStudentList = (updatedStudents) => {
    console.log("Updating student list with:", updatedStudents);
    setUnaccountedStudents((prevUnaccountedStudents) =>
      prevUnaccountedStudents.filter(
        (student) =>
          !updatedStudents.some(
            (updatedStudent) => updatedStudent.id === student.id
          )
      )
    );

    setAccountedForStudents((prevAccountedForStudents) => [
      ...prevAccountedForStudents,
      ...updatedStudents.filter(
        (updatedStudent) =>
          !prevAccountedForStudents.some(
            (accountedForStudent) =>
              accountedForStudent.id === updatedStudent.id
          )
      ),
    ]);
  };

  const handleStudentClick = (studentId) => {
    console.log("Clicked on student with ID:", studentId);
    setSelectedStudentId(studentId);
  };

  const handleSelectClass = (selectedClass) => {
    console.log("Selected class:", selectedClass);
    setSelectedClass(selectedClass);
  };

  const handleClickDelete = async (studentId) => {
    try {
      console.log("Initiating delete for student with ID:", studentId);
      const currentDate = new Date().toISOString().slice(0, 10);
      const emergencyDocRef = doc(
        firestore,
        "schools",
        schoolId,
        "emergencies",
        currentDate,
        "students",
        studentId
      );

      await deleteDoc(emergencyDocRef);

      console.log("Deleting record for student (sid):", studentId);

      // No need to wait, as it's an asynchronous operation
      fetchAndHandleLatestStudentData();
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  const markEmergency = async (studentId) => {
    try {
      console.log("Marking emergency for student with ID:", studentId);
      const schoolRef = doc(firestore, "schools", schoolId);
      const emergencyCollectionRef = collection(schoolRef, "emergencies");

      const currentDate = new Date().toISOString().slice(0, 10);
      const emergencyDocRef = doc(emergencyCollectionRef, currentDate);

      const currentTimestamp = serverTimestamp();
      const emergencyData = {
        timestamp: currentTimestamp,
        sid: studentId,
        clickedBy: user.displayName || "Unknown User",
      };

      const studentEmergencyCollectionRef = collection(
        emergencyDocRef,
        "students"
      );

      await setDoc(
        doc(studentEmergencyCollectionRef, studentId),
        emergencyData
      );

      console.log("Emergency marked for student (sid):", studentId);

      setUnaccountedStudents((prevUnaccountedStudents) =>
        prevUnaccountedStudents.filter((student) => student.id !== studentId)
      );

      setAccountedForStudents((prevAccountedForStudents) => [
        ...prevAccountedForStudents,
        {
          id: studentId,
          timestamp: currentTimestamp,
          clickedBy: user.displayName || "Unknown User",
        },
      ]);
    } catch (error) {
      console.error("Error marking emergency:", error);
    }
  };

  const filteredStudents = fullSchoolList.filter((student) => {
    if (selectedClass === "") {
      return enrolledClasses.some((enrolledClass) =>
        student.enrolledClasses.includes(enrolledClass.classId)
      );
    } else {
      return student.enrolledClasses.includes(selectedClass);
    }
  });

  // Sort the filteredStudents array by last name
  const sortedStudents = filteredStudents.slice().sort((a, b) => {
    const lastNameA = a.lastName || ""; // Replace 'lastName' with the actual field name in your data
    const lastNameB = b.lastName || ""; // Replace 'lastName' with the actual field name in your data

    // Compare last names in a case-insensitive manner
    return lastNameA.localeCompare(lastNameB, undefined, {
      sensitivity: "base",
    });
  });

  return (
    <div>
      <h2>Full School List</h2>
      <EnrolledClassesFilter
        className="class-select"
        style={{ width: "200px", fontSize: "18px", margin: "20px" }}
        onSelectClass={handleSelectClass}
      />
      <StudentList
        key={selectedClass}
        students={sortedStudents}
        onStudentClick={handleStudentClick}
        markEmergency={markEmergency}
        handleClickDelete={handleClickDelete}
      />
    </div>
  );
};

export default SchoolList;
