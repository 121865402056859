import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./NavBar.css";
import logo from "../images/ClassBOON.svg";
import BasicExample from "./dropdown";

function NavBar() {
  const [loggedIn, setLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const shouldChangeColor = () => {
    return location.pathname === "/schoollist";
  };

  return (
    <nav className={`navbar ${shouldChangeColor() ? "emergency-bg" : ""}`}>
      <div className="navbar-section navbar-left">
        <div className="navbar-menu-item">
          {loggedIn && location.pathname !== "/schoollist" && (
            <Link to="/attendance">Attendance</Link>
          )}
        </div>
        <div className="navbar-menu-item">
          {loggedIn && location.pathname !== "/schoollist" && (
            <Link to="/classsignoutpage">Sign Out</Link>
          )}
        </div>
      </div>
      <div className="navbar-section navbar-middle">
        <div className="logo-container">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="navbar-section navbar-right">
        {loggedIn ? (
          <div className="flex-grow"></div>
        ) : (
          <div className="navbar-menu-item">
            <Link to="/register">Register</Link>
          </div>
        )}
        <div className="navbar-menu-item">
          {loggedIn ? (
            <BasicExample />
          ) : (
            <Link to="/loginform" className="navbar-menu-item">
              Log In
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
