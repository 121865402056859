import { useState, useEffect, useContext } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore, auth } from "./FirebaseConfig";
import { UserContext } from "./UserContext";

const useFetchClasses = () => {
  console.log("The useFetchClasses Hook has fired!");
  const { user } = useContext(UserContext);
  const [classList, setClassList] = useState([]);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        if (user) {
          const classesCollectionRef = collection(firestore, "classes");
          const q = query(
            classesCollectionRef,
            where("teacherId", "==", user.uid)
          );
          const querySnapshot = await getDocs(q);

          const classesData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setClassList(classesData);
        }
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };
    console.log("FetchClasses Hook Classlist: ", classList);
    fetchClasses();
  }, [user]);

  return { classList };
};

export default useFetchClasses;
