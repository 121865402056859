// EnrolledClassesFilter.js

import React from "react";
import useEnrolledClassesDropdown from "./useEnrolledClassesDropdown";
import "./Emergency.css";

const EnrolledClassesFilter = ({ onSelectClass }) => {
  const { enrolledClasses } = useEnrolledClassesDropdown();

  const handleChange = (event) => {
    const selectedClassId = event.target.value;
    onSelectClass(selectedClassId); // Ensure this line is calling onSelectClass
  };

  return (
    <div>
      <label>Filter by Class:</label>
      <select
        className="class-select"
        style={{ width: "200px", fontSize: "18px", margin: "20px" }}
        onChange={(e) => onSelectClass(e.target.value)}
      >
        {" "}
        <option value="">All Classes</option>
        {enrolledClasses.map(({ classId, className }) => (
          <option key={classId} value={classId}>
            {className}
          </option>
        ))}
      </select>
    </div>
  );
};

export default EnrolledClassesFilter;
