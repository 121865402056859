import React, { useState, useContext } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import { firestore } from "./FirebaseConfig";

import "./forms.css"; // Import the forms.css file
import "./modal.css"; // Import the modal.css styles

const RegistrationForm = () => {
  const { setUid } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [title, setTitle] = useState("Ms.");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("Teacher");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, email, password);
      console.log("User registered successfully!");

      let displayName = "";
      if (title === "Other") {
        displayName = `${firstName} ${lastName}`;
      } else {
        displayName = `${title} ${lastName}`;
      }

      const usersCollectionRef = collection(firestore, "users");
      const docRef = doc(usersCollectionRef, auth.currentUser.uid);

      await updateProfile(auth.currentUser, { displayName });

      await setDoc(docRef, {
        email,
        uid: auth.currentUser.uid,
        title,
        firstName,
        lastName,
        displayName,
        role,
      });

      const uid = auth.currentUser.uid;
      console.log("uid:", uid, "User was sucessfully added");

      // After successful registration, navigate to the AfterRegistrationSchoolSelection page

      navigate("/afterregistrationschoolselection");
    } catch (error) {
      console.error("Error registering user:", error);
      setError("Registration failed. Please try again.");
    }
  };

  return (
    <div className="form-container">
      <h2>Registration</h2>
      <form className="registration-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <label htmlFor="title">Title:</label>
          <select
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          >
            <option value="Ms.">Ms.</option>
            <option value="Miss">Miss</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Mr.">Mr.</option>
            <option value="Dr.">Dr.</option>
            <option value="Prof.">Prof.</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-row">
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="role">Role:</label>
          <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="Teacher">Teacher</option>
          </select>
        </div>
        <div className="form-row">
          <button type="submit">Register</button>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
