import React, { useState } from "react";
import useFetchStudents from "./useFetchStudents";
import useFetchClasses from "./useFetchClasses";

const ClassEnrollment = () => {
  const { classList } = useFetchClasses();
  const [selectedClassId, setSelectedClassId] = useState("");
  const { students } = useFetchStudents(selectedClassId);
  console.log("Students: ", students);

  const handleClassChange = (event) => {
    const selectedId = event.target.value;
    setSelectedClassId(selectedId);
  };

  return (
    <div>
      <h1>Class Enrollment</h1>
      <div>
        <label>Select Class:</label>
        <select onChange={handleClassChange}>
          <option value="">Select a class</option>
          {classList.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.className}
            </option>
          ))}
        </select>
      </div>
      {selectedClassId && (
        <div>
          <h2>Enrolled Students for Class {selectedClassId}</h2>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id}>
                  <td>{student.studentName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ClassEnrollment;
