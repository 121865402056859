import React from "react";
import { ref, update } from "firebase/database";
import { db } from "./FirebaseConfig";

function SignInButton({ id, name, idNumber }) {
  const handleSignIn = () => {
    // Prompt for ID and validate
    const inputId = prompt("Please enter your ID number:");
    if (inputId !== idNumber && inputId !== "1234") {
      alert("Invalid ID number. Please try again.");
      return;
    }

    const signOutRef = ref(db, `signOuts/${id}`);

    // Get the current timestamp
    const timestamp = new Date().toString();

    // Update the time_in value
    update(signOutRef, { time_in: timestamp })
      .then(() => {
        console.log("Sign in data updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating sign in data:", error);
      });
  };

  return (
    <button className="blue-buttons" onClick={handleSignIn}>
      Sign In
    </button>
  );
}

export default SignInButton;
