import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import RegistrationForm from "./components/RegistrationForm";
import Home from "./components/Home";
import About from "./components/About";
import LogInForm from "./components/LogInForm";
import Dashboard from "./components/Dashboard";
import ClassSignOutPage from "./components/ClassSignOutPage";
import { UserProvider } from "./components/UserContext";
import AddClass from "./components/AddClass";
import AddStudent from "./components/AddStudent"; // Import the AddStudents component
import AfterRegistrationSchoolSelection from "./components/AfterRegistrationSchoolSelection";
import SignOutsTeacherRecord from "./components/SignOutsTeacherRecord";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Updated import for auth

import "./App.css";
import ClassEnrollment from "./components/ClassEnrollment";
import Attendance from "./components/Attendance";
import AttendanceRecord from "./components/AttendanceRecord";
import ClassDetails from "./components/ClassDetails";
import Invitations from "./components/Invitations";
import Emergency from "./components/Emergency";
import SchoolList from "./components/SchoolList";
import SchoolListWithEmergencies from "./components/SchoolListWithEmergencies";

function App() {
  useEffect(() => {
    const auth = getAuth(); // Use getAuth from the new modular syntax
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is logged in:", user.email);
      } else {
        console.log("User is not logged in");
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <UserProvider>
        <div className="app-container">
          <NavBar />
          <div className="content-container">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/register" element={<RegistrationForm />} />
              <Route path="/loginform" element={<LogInForm />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/classsignoutpage" element={<ClassSignOutPage />} />
              <Route path="/addclass" element={<AddClass />} />
              <Route
                path="/afterregistrationschoolselection"
                element={<AfterRegistrationSchoolSelection />}
              />
              <Route path="/addstudent/:classId" element={<AddStudent />} />
              <Route
                path="/signoutsteacherrecord"
                element={<SignOutsTeacherRecord />}
              />
              <Route path="/classenrollment" element={<ClassEnrollment />} />
              <Route path="/attendance" element={<Attendance />} />
              <Route path="/attendancerecord" element={<AttendanceRecord />} />
              <Route path="/classdetails" element={<ClassDetails />} />
              <Route path="/invitations" element={<Invitations />} />
              <Route path="/emergency" element={<Emergency />} />
              <Route path="/schoollist" element={<SchoolList />} />
              <Route
                path="/schoollistwithemergencies"
                element={<SchoolListWithEmergencies />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </UserProvider>
    </Router>
  );
}

function NotFound() {
  return <h1>404 Not Found</h1>;
}

export default App;
