import React, { useEffect, useState, useContext } from "react";
import {
  ref,
  query,
  equalTo,
  orderByChild,
  onValue,
  off,
} from "firebase/database";
import SignInButton from "./SignInButton";
import { db } from "./FirebaseConfig";
import { UserContext } from "./UserContext";

import "./DisplaySignOut.css";

function formatTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

function DisplaySignOut() {
  const [signOutData, setSignOutData] = useState([]);
  const user = useContext(UserContext);
  const uid = user.uid;

  useEffect(() => {
    const signOutsRef = ref(db, "signOuts");
    const queryRef = query(signOutsRef, orderByChild("time_in"), equalTo(null));

    const handleSnapshot = (snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
        const signOutData = {
          id: doc.key,
          ...doc.val(),
        };
        data.push(signOutData);
      });
      setSignOutData(data);
    };

    const errorCallback = (error) => {
      console.error("Error retrieving sign-out data:", error);
    };

    onValue(queryRef, handleSnapshot, { errorCallback });

    return () => {
      off(queryRef, handleSnapshot);
    };
  }, []);

  const filteredSignOutData = signOutData.filter(
    (signOut) => signOut.teacherUID === uid
  );

  return (
    <div className="display-sign-out-container">
      <table className="display-sign-out-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Time Out</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredSignOutData.map((signOut) => (
            <tr key={signOut.id}>
              <td>{signOut.studentName}</td>
              <td>{signOut.location}</td>
              <td>{formatTime(signOut.time_out)}</td>
              <td>
                <SignInButton
                  id={signOut.id}
                  name={signOut.name}
                  idNumber={signOut.idNumber}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DisplaySignOut;
