import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import menu from "../images/three_line_menu.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

function BasicExample() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      navigate("/loginform");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const shouldChangeColor = () => {
    return location.pathname === "/schoollist";
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant={shouldChangeColor() ? "danger" : "success"}
        id="dropdown-basic"
      >
        {" "}
        <img src={menu} alt="menu" />
      </Dropdown.Toggle>

      <Dropdown.Menu className={shouldChangeColor() ? "emergency-bg" : ""}>
        {shouldChangeColor() && (
          <>
            <Dropdown.Item as={Link} to="/attendance">
              Attendance
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/classsignoutpage">
              Sign Out
            </Dropdown.Item>
          </>
        )}
        <Dropdown.Item as={Link} to="/dashboard">
          Classes
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/invitations">
          Invitations
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/attendancerecord">
          Attendance Log
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/signoutsteacherrecord">
          Sign Out Log
        </Dropdown.Item>

        <Dropdown.Item as={Link} to="/schoollist">
          Emergency
        </Dropdown.Item>
        <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default BasicExample;
