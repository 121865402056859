import React from "react";
import useFullSchoolList from "./useFullSchoolList";
import useFilteredEmergencyStudents from "./useFilteredEmergencyStudents";
import StudentList from "./StudentList";

const SchoolListWithEmergencies = ({ selectedClassId }) => {
  const { fullSchoolList } = useFullSchoolList(selectedClassId);
  const {
    notAccountedForStudents,
    accountedForStudents,
    refreshFilteredEmergencyStudents,
  } = useFilteredEmergencyStudents(selectedClassId);

  const formatTimestamp = (timestamp) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(timestamp).toLocaleString(undefined, options);
  };

  const handleStudentClick = (studentId) => {
    // Handle student click if needed
    console.log("Student clicked:", studentId);
  };

  const markEmergency = (studentId) => {
    // Mark emergency if needed
    console.log("Mark emergency for student:", studentId);
    refreshFilteredEmergencyStudents(); // Refresh the list after marking emergency
  };

  return (
    <div>
      <h2>Full School List with Emergencies</h2>
      {/* Display the full school list */}
      <StudentList
        students={fullSchoolList}
        onStudentClick={handleStudentClick}
        markEmergency={markEmergency}
      />

      {/* Display the not accounted for students */}
      <div className="student-heading">
        <h3>Not Accounted For</h3>
      </div>
      <div className="student-grid">
        {notAccountedForStudents.map((student) => (
          <div
            key={student.id}
            className={`student-item-not-accounted`}
            onClick={() => {
              handleStudentClick(student.id);
              markEmergency(student.id);
            }}
          >
            <span className="student-name">{student.studentName}</span>
          </div>
        ))}
      </div>

      {/* Display the accounted for students */}
      <div className="student-heading">
        <h3>Accounted For</h3>
      </div>
      <div className="student-grid">
        {accountedForStudents.map((student) => (
          <div
            key={student.id}
            className={`student-item-accounted`}
            onClick={() => markEmergency(student.id)}
          >
            <div className="accounted-for-content">
              <span className="student-name">{student.studentName}</span>
              <span className="clicked-by">{student.clickedBy}</span>
              <span className="timestamp smaller-timestamp">
                {formatTimestamp(student.timestamp)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SchoolListWithEmergencies;
