import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { firestore } from "./FirebaseConfig";
import { firestore as dbFirestore } from "./FirebaseConfig";

const Invitations = ({ user }) => {
  console.log("Invitations");
  const [invitations, setInvitations] = useState([]);

  // Function to add classId to the user's activeClasses array
  const addClassToUser = async (userId, classId) => {
    try {
      const userRef = doc(firestore, "users", userId);
      await updateDoc(userRef, {
        activeClasses: arrayUnion(classId),
      });
    } catch (error) {
      console.error("Error adding class to user:", error);
    }
  };

  const getClassDetails = async (classId) => {
    try {
      const classDoc = await getDoc(doc(firestore, "classes", classId));
      if (classDoc.exists()) {
        return classDoc.data().className;
      }
    } catch (error) {
      console.error("Error fetching class details:", error);
    }
    return "Unknown Class"; // Default value if class is not found
  };

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const q = query(
          collection(firestore, "invitations"),
          where("invitedToEmail", "==", user.email),
          where("status", "==", "pending")
        );

        const querySnapshot = await getDocs(q);
        const invitationData = [];

        for (const doc of querySnapshot.docs) {
          const invitation = doc.data();
          const className = await getClassDetails(invitation.classId);
          invitation.className = className;
          invitationData.push({ id: doc.id, ...invitation });
        }

        setInvitations(invitationData);
      } catch (error) {
        console.error("Error fetching invitations:", error);
      }
    };

    fetchInvitations();
  }, [user]);

  const handleAcceptInvitation = async (invitationId, userRole) => {
    try {
      // Update the invitation status to "accepted"
      const invitationRef = doc(firestore, "invitations", invitationId);
      await updateDoc(invitationRef, { status: "accepted" });

      // Get the class data from the invitation
      const invitationSnapshot = await getDoc(invitationRef);
      if (invitationSnapshot.exists()) {
        const classId = invitationSnapshot.data().classId;

        // Add the classId to the user's activeClasses array
        await addClassToUser(user.uid, classId);

        // Get the class document reference
        const classDocRef = doc(firestore, "classes", classId);
        const classDocSnapshot = await getDoc(classDocRef);

        // Check if the class document exists
        if (classDocSnapshot.exists()) {
          const classData = classDocSnapshot.data();
          console.log(classData);

          // Update the appropriate field based on the user's role
          if (userRole === "co-teacher") {
            console.log("Attempting to add a Co-Teacher");
            await updateDoc(classDocRef, {
              coTeachers: arrayUnion(user.uid),
            });
          } else if (userRole === "other") {
            console.log("Attempting to add an Other Teacher");

            await updateDoc(classDocRef, {
              otherTeachers: arrayUnion(user.uid),
            });
          }
        }
      }

      // Update the state to remove the declined invitation
      setInvitations((prevInvitations) =>
        prevInvitations.filter((invitation) => invitation.id !== invitationId)
      );
    } catch (error) {
      console.error("Error accepting invitation:", error);
    }
  };

  const handleDeclineInvitation = async (invitationId) => {
    try {
      const invitationRef = doc(firestore, "invitations", invitationId);
      await updateDoc(invitationRef, { status: "declined" });

      // Update the state to remove the declined invitation
      setInvitations((prevInvitations) =>
        prevInvitations.filter((invitation) => invitation.id !== invitationId)
      );
    } catch (error) {
      console.error("Error declining invitation:", error);
    }
  };

  return (
    <div>
      {invitations.length > 0 ? (
        <div>
          <h2>Pending Invitations</h2>
          <ul>
            {invitations.map((invitation) => (
              <li key={invitation.id}>
                <p>Invited by: {invitation.invitedByEmail}</p>
                <p>Class: {invitation.className}</p>
                <p>Role: {invitation.teacherRole}</p>

                <button
                  onClick={() =>
                    handleAcceptInvitation(
                      invitation.id,
                      invitation.teacherRole
                    )
                  }
                >
                  Accept
                </button>
                <button onClick={() => handleDeclineInvitation(invitation.id)}>
                  Decline
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>You currently have no invitations.</p>
      )}
    </div>
  );
};

export default Invitations;
