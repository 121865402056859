import React, { useState, useEffect, useContext } from "react";
import { doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Update the import for Auth
import { firestore } from "./FirebaseConfig";

const UserContext = React.createContext();

const useUserContext = () => {
  const [user, setUser] = useState(null);
  const [uid, setUid] = useState(null);
  const [schoolId, setSchoolId] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      setUid(user?.uid);

      if (user) {
        try {
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const schoolId = userDocSnapshot.get("schoolId");
            setSchoolId(schoolId);
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  return {
    user,
    uid,
    schoolId,
    displayName: user ? user.displayName : "",
  };
};

const UserProvider = ({ children }) => {
  return (
    <UserContext.Provider value={useUserContext()}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUser, UserContext };
