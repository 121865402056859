import React, { useContext, useState, useEffect } from "react";
import useEmergencyStatus from "./EmergencyStatus";
import { UserContext } from "./UserContext";
import {
  getFirestore,
  firestore,
  collection,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";

import "./Emergency.css";

const StudentList = ({
  students,
  onStudentClick,
  markEmergency,
  handleClickDelete,
}) => {
  const { schoolId } = useContext(UserContext);
  const [classesData, setClassesData] = useState([]);
  const firestore = getFirestore(); // initialize firestore

  // Fetch classes data from the database
  useEffect(() => {
    const fetchClassesData = async () => {
      try {
        const classesCollection = collection(firestore, "classes");
        const classesSnapshot = await getDocs(classesCollection);
        const classesData = classesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClassesData(classesData);
      } catch (error) {
        console.error("Error fetching classes data:", error);
        setClassesData([]);
      }
    };

    fetchClassesData();
  }, []);

  // Create a classMap
  const classMap = {};
  classesData.forEach((classInfo) => {
    classMap[classInfo.id] = classInfo;
  });

  const { accountedForStudents, unaccountedStudents } = useEmergencyStatus(
    students,
    schoolId
  );
  const formatTimestamp = (timestamp) => {
    if (!timestamp || !(timestamp instanceof Date) || isNaN(timestamp)) {
      return "Invalid Date";
    }

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(timestamp).toLocaleString(undefined, options);
  };

  const handleNotAccountedForClick = (student) => {
    console.log("Clicked on not accounted for student:", student);
    onStudentClick(student.id);
    markEmergency(student.id);
  };

  const handleAccountedForClick = (student) => {
    console.log("Clicked on accounted for student:", student);
    handleClickDelete(student.id);
  };

  return (
    <div className="emergency-container">
      {/* Your existing content for Not Accounted For Students */}
      <div className="student-heading">
        <h3>Not Accounted For</h3>
      </div>
      <div className="student-grid">
        {unaccountedStudents.map((student) => (
          <div
            key={student.id}
            className={`student-item-not-accounted`}
            onClick={() => handleNotAccountedForClick(student)}
          >
            <span className="student-name">{student.studentName}</span>
            <span className="grade">{student.grade}</span>
            <span className="enrolled-classes">
              {/* Displaying class name based on classId */}
              {student.enrolledClasses
                .map((classId) => {
                  const classInfo = classMap[classId]; // Assuming classMap is available
                  return classInfo ? classInfo.className : "Unknown Class";
                })
                .join(", ")}
            </span>
          </div>
        ))}
      </div>

      {/* Your existing content for Accounted For Students */}
      <div className="student-heading">
        <h3>Accounted For</h3>
      </div>
      <div className="student-grid">
        {accountedForStudents.map((student) => (
          <div
            key={student.id}
            className={`student-item-accounted`}
            onClick={() => handleAccountedForClick(student)}
          >
            <div className="accounted-for-content">
              <span className="student-name">{student.studentName}</span>
              <span className="clicked-by">{student.clickedBy}</span>
              <span className="timestamp smaller-timestamp">
                {formatTimestamp(student.timestamp)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentList;
