import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  updateDoc,
  getFirestore,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { useParams } from "react-router-dom";

function ClassDetails() {
  const { classId } = useParams();
  const [classData, setClassData] = useState(null);
  const [students, setStudents] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // Fetch class details and students when component mounts
    const fetchData = async () => {
      const db = getFirestore();
      const classRef = doc(db, "classes", classId);
      const classSnapshot = await getDoc(classRef);
      if (classSnapshot.exists()) {
        const classDetails = classSnapshot.data();
        setClassData(classDetails);
      }

      // Assuming you have a field in classData that contains enrolled student IDs
      if (classData && classData.enrolledStudents) {
        const studentPromises = classData.enrolledStudents.map(
          async (studentId) => {
            const studentRef = doc(db, "students", studentId);
            const studentSnapshot = await getDoc(studentRef);
            if (studentSnapshot.exists()) {
              return studentSnapshot.data();
            }
            return null;
          }
        );

        const studentData = await Promise.all(studentPromises);
        setStudents(studentData.filter((student) => student !== null));
      }
    };

    fetchData();
  }, [classId, classData]);

  const handleEditClass = async (updatedClassData) => {
    // Update class data in Firestore
    const db = getFirestore();
    const classRef = doc(db, "classes", classId);
    await updateDoc(classRef, updatedClassData);
    setIsEditing(false);
  };

  const handleEditStudent = async (studentId, updatedStudentData) => {
    // Update student data in Firestore
    const db = getFirestore();
    const studentRef = doc(db, "students", studentId);
    await updateDoc(studentRef, updatedStudentData);
  };

  if (!classData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{classData.className}</h1>

      <h2>Teachers</h2>
      <ul>
        {classData.addedBy && <li>Class Teacher: {classData.addedBy}</li>}
        {classData.coTeachers && (
          <li>Co-Teachers: {classData.coTeachers.join(", ")}</li>
        )}
        {classData.otherTeachers && (
          <li>Other Teachers: {classData.otherTeachers.join(", ")}</li>
        )}
      </ul>

      <h2>Students</h2>
      <ul>
        {students.map((student) => (
          <li key={student.id}>
            {student.studentName} - Student ID: {student.studentId}
            <button
              onClick={() =>
                handleEditStudent(student.id, {
                  /* Provide updated student data */
                })
              }
            >
              Edit Student
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ClassDetails;
