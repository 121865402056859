// EmergencyStatus.js
import React, { useState, useEffect } from "react";
import { doc, collection, getDocs, getDoc } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";

const useEmergencyStatus = (fullSchoolList, schoolId) => {
  const [accountedForStudents, setAccountedForStudents] = useState([]);
  const [unaccountedStudents, setUnaccountedStudents] = useState([]);

  useEffect(() => {
    const fetchEmergencyStatus = async () => {
      try {
        if (!schoolId) {
          console.log("Invalid school ID. Skipping fetchEmergencyStatus.");
          return;
        }

        const currentDate = new Date().toISOString().slice(0, 10);
        const schoolRef = doc(firestore, "schools", schoolId);
        const emergencyCollectionRef = collection(schoolRef, "emergencies");
        const emergencyDocRef = doc(emergencyCollectionRef, currentDate);
        const studentEmergencyCollectionRef = collection(
          emergencyDocRef,
          "students"
        );

        const accountedSnapshot = await getDocs(studentEmergencyCollectionRef);
        const accountedStudents = accountedSnapshot.docs.map((doc) => doc.id);

        const accountedForStudentsData = await Promise.all(
          fullSchoolList.map(async (student) => {
            try {
              const studentDocRef = doc(
                studentEmergencyCollectionRef,
                student.id
              );
              const studentDoc = await getDoc(studentDocRef);

              if (studentDoc.exists()) {
                const { clickedBy, timestamp } = studentDoc.data();
                return {
                  ...student,
                  clickedBy,
                  timestamp: timestamp ? timestamp.toDate() : null,
                };
              }

              return null;
            } catch (error) {
              console.error(
                `Error fetching details for student ${student.id}:`,
                error
              );
              return null;
            }
          })
        );

        const unaccountedStudentsData = fullSchoolList.filter(
          (student) => !accountedStudents.includes(student.id)
        );

        setAccountedForStudents(accountedForStudentsData.filter(Boolean));
        setUnaccountedStudents(unaccountedStudentsData);
      } catch (error) {
        console.error("Error fetching emergency status:", error);
        setAccountedForStudents([]);
        setUnaccountedStudents([]);
      }
    };

    fetchEmergencyStatus();
  }, [schoolId, fullSchoolList]);

  return { accountedForStudents, unaccountedStudents };
};

export default useEmergencyStatus;
