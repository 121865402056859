import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  serverTimestamp,
  updateDoc,
  getFirestore,
  FieldValue,
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAQR0SG68j8RxS0tKDVcGau-ZiQ9VwOX-o",
  authDomain: "classboon.firebaseapp.com",
  databaseURL: "https://classboon-default-rtdb.firebaseio.com",
  projectId: "classboon",
  storageBucket: "classboon.appspot.com",
  messagingSenderId: "479379000350",
  appId: "1:479379000350:web:0e0a7e1a399b10aa21320f",
  measurementId: "G-ELX885FFE3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const firestore = getFirestore(app);
const auth = getAuth(); // Use getAuth() to get the 'auth' instance

// Handle authentication state changes
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // eslint-disable-next-line no-unused-vars
    const uid = user.uid;
    console.log("User is signed in");
  } else {
    // User is signed out
    console.log("User is signed out");
  }
});

const firebase = app;

export {
  app,
  auth, // Export the 'auth' instance
  firestore,
  collection,
  doc,
  db,
  firebase,
  updateDoc,
  serverTimestamp,
  FieldValue,
};
