// useFullSchoolList.js
import { useState, useEffect } from "react";
import { firestore } from "./FirebaseConfig";
import {
  collection,
  query,
  getDocs,
  where,
  doc,
  getDoc,
} from "firebase/firestore";

const useFullSchoolList = () => {
  const [fullSchoolList, setFullSchoolList] = useState([]);
  const [classMap, setClassMap] = useState({});
  const [unaccountedStudents, setUnaccountedStudents] = useState([]);

  useEffect(() => {
    const fetchFullSchoolList = async () => {
      try {
        const studentsQuery = query(collection(firestore, "students"));
        const studentsSnapshot = await getDocs(studentsQuery);
        const studentsData = studentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Set the fullSchoolList state
        setFullSchoolList(studentsData);

        // Update unaccountedStudents state
        setUnaccountedStudents((prevUnaccountedStudents) =>
          prevUnaccountedStudents.filter((student) =>
            studentsData.some((newStudent) => newStudent.id === student.id)
          )
        );
      } catch (error) {
        console.error("Error fetching full school list:", error);
        setFullSchoolList([]);
      }
    };

    const fetchClassMap = async () => {
      try {
        const classesQuery = query(collection(firestore, "classes"));
        const classesSnapshot = await getDocs(classesQuery);
        const classData = {};

        classesSnapshot.forEach((classDoc) => {
          const classId = classDoc.id;
          const className = classDoc.data().name;
          classData[classId] = className;
        });

        setClassMap(classData);
      } catch (error) {
        console.error("Error fetching class map:", error);
        setClassMap({});
      }
    };

    fetchFullSchoolList();
    fetchClassMap();
  }, []);

  return { fullSchoolList, classMap, unaccountedStudents };
};

export default useFullSchoolList;
