import React, { useState, useContext, useEffect } from "react";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getFirestore,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { firestore, auth } from "./FirebaseConfig";
import useFetchClasses from "./useFetchClasses";
import { UserContext } from "./UserContext";
import { useParams } from "react-router-dom";
import DisplayEnrolledStudentsTable from "./DisplayEnrolledStudentsTable";

import "./AddStudent.css";

const parseStudentList = (list) => {
  const lines = list.split("\n");
  const students = [];

  for (const line of lines) {
    const [fullName, id, grade] = line.split("\t");
    const [firstName, ...lastNameArray] = fullName.split(" ");
    const lastName = lastNameArray.join(" "); // Join the remaining parts as the last name

    students.push({
      studentName: fullName,
      firstName,
      lastName,
      id,
      grade,
    });
  }

  return students;
};

const gradeLevelOptions = [
  "Pre-K",
  "Kindergarten",
  "1st Grade",
  "2nd Grade",
  "3rd Grade",
  "4th Grade",
  "5th Grade",
  "6th Grade",
  "7th Grade",
  "8th Grade",
  "9th Grade",
  "10th Grade",
  "11th Grade",
  "12th Grade",
];

const AddStudent = () => {
  const { user } = useContext(UserContext);
  const { classList } = useFetchClasses();
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedGrade, setSelectedGrade] = useState(""); // New state for selected grade
  const [pastedStudentList, setPastedStudentList] = useState("");
  const { classId } = useParams();

  useEffect(() => {
    setSelectedClass(classId);
  }, [classId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const studentName = event.target.studentName.value;
    const studentId = event.target.studentId.value;

    try {
      const db = getFirestore();
      const studentsCollectionRef = collection(db, "students");

      const userRef = doc(db, "users", auth.currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      const newStudentData = {
        studentName,
        studentId,
        grade: selectedGrade, // Add selected grade
        enrolledClasses: [selectedClass],
        schoolId: userData.schoolId,
        addedBy: auth.currentUser.uid,
      };

      const studentDocRef = await addDoc(studentsCollectionRef, newStudentData);

      const classDocRef = doc(db, "classes", selectedClass);
      await updateDoc(classDocRef, {
        enrolledStudents: arrayUnion(studentDocRef.id),
      });

      event.target.studentName.value = "";
      event.target.studentId.value = "";
      setSelectedClass("");
      setSelectedGrade(""); // Clear selected grade
    } catch (error) {
      console.error("Error adding student:", error);
    }
  };

  const handleBulkImport = () => {
    const students = parseStudentList(pastedStudentList);
    console.log("Parsed Student List: ", students);

    if (selectedClass) {
      for (const student of students) {
        const { studentName, firstName, lastName, id, grade } = student;
        handleSubmitSingle(studentName, firstName, lastName, id, grade);
        console.log(
          "Bulk import studentName, firstName, lastName, id, and grade: ",
          studentName,
          firstName,
          lastName,
          id,
          grade
        );
      }
    } else {
      console.log("No class selected for bulk import");
    }
  };

  const handleSubmitSingle = async (
    studentName,
    firstName,
    lastName,
    studentId,
    grade
  ) => {
    try {
      const db = getFirestore();
      const studentsCollectionRef = collection(db, "students");

      const userRef = doc(db, "users", auth.currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      const newStudentData = {
        studentName,
        firstName,
        lastName,
        studentId,
        grade,
        enrolledClasses: [selectedClass],
        schoolId: userData.schoolId,
        addedBy: auth.currentUser.uid,
      };

      const studentDocRef = await addDoc(studentsCollectionRef, newStudentData);

      const classDocRef = doc(db, "classes", selectedClass);
      await updateDoc(classDocRef, {
        enrolledStudents: arrayUnion(studentDocRef.id),
      });

      setSelectedClass("");
    } catch (error) {
      console.error("Error adding student:", error);
    }
  };

  return (
    <div>
      <div className="add-student-form-container">
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="classSelect" style={{ fontSize: "24px" }}>
              Select a Class:
            </label>
            <select
              id="classSelect"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
              required
              style={{ width: "200px", fontSize: "18px", margin: "20px" }} // Adjust width and font size as needed
            >
              <option value="">-- Select a Class --</option>
              {classList.map((classData) => (
                <option key={classData.id} value={classData.id}>
                  {classData.className}
                </option>
              ))}
            </select>
          </div>

          <div className="side-by-side-container">
            <div className="side-form">
              <h2> Add Individual Student</h2>
              <div>
                <label htmlFor="studentName">Student Name:</label>
                <input
                  type="text"
                  id="studentName"
                  name="studentName"
                  required
                />
              </div>
              <div>
                <label htmlFor="studentId">Student ID:</label>
                <input type="text" id="studentId" name="studentId" required />
              </div>
              <div>
                <label htmlFor="grade">Grade:</label>
                <select
                  id="grade"
                  name="grade"
                  value={selectedGrade}
                  onChange={(e) => setSelectedGrade(e.target.value)}
                  required
                >
                  <option value="">-- Select Grade --</option>
                  {gradeLevelOptions.map((grade) => (
                    <option key={grade} value={grade}>
                      {grade}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit">Add Student</button>
            </div>

            <div className="side-form">
              <h2> Try the Bulk Importer</h2>
              {/* Pasted Student List */}
              <label htmlFor="pastedStudentList">
                Name | Student ID # | Grade Level:
              </label>
              <textarea
                value={pastedStudentList}
                onChange={(e) => setPastedStudentList(e.target.value)}
                placeholder="Paste student list here. Copy from a spreadsheet.  Columns should be in this order Name | Student ID | Grade Level"
                style={{
                  height: "200px",
                  fontSize: "16px",
                }} // Adjust width and font size as needed
              />
              <button onClick={handleBulkImport}>Bulk Import Students</button>
            </div>
          </div>
        </form>
      </div>

      <DisplayEnrolledStudentsTable
        classList={classList}
        selectedClass={selectedClass}
      />
    </div>
  );
};

export default AddStudent;
