import React from "react";
import useEnrolledStudents from "./useEnrolledStudents";
import {
  collection,
  updateDoc,
  doc,
  getFirestore,
  arrayRemove,
} from "firebase/firestore";

import "./AddStudent.css";

const DisplayEnrolledStudentsTable = ({ classList, selectedClass }) => {
  const { studentIdMap, studentNamesMap, studentGradeMap } =
    useEnrolledStudents();

  const handleDeleteStudentFromClass = async (classId, studentId) => {
    try {
      const db = getFirestore();

      // Remove studentId from the enrolledStudents array of the class
      const classDocRef = doc(db, "classes", classId);
      await updateDoc(classDocRef, {
        enrolledStudents: arrayRemove(studentId),
      });

      // You might also want to update the enrolledClasses array of the student
      // to remove the current classId here
    } catch (error) {
      console.error("Error deleting student:", error);
    }
  };

  return (
    <div className="display-enrolled-students-container">
      <h2>Enrolled Students</h2>

      {classList.map((classData) => (
        <div key={classData.id}>
          {selectedClass === classData.id && ( // Check if selectedClass matches
            <div>
              <h3>{classData.className}</h3>
              {classData.enrolledStudents &&
              classData.enrolledStudents.length > 0 ? (
                <table className="enrolled-students-table">
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      <th>Student ID</th>
                      <th>Grade</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {classData.enrolledStudents.map((sid) => (
                      <tr key={sid}>
                        <td>{studentNamesMap[sid] || "Unknown Student"}</td>
                        <td>{studentIdMap[sid]}</td>
                        <td>{studentGradeMap[sid]}</td>
                        <td>
                          <button
                            onClick={() =>
                              handleDeleteStudentFromClass(classData.id, sid)
                            }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No enrolled students.</p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default DisplayEnrolledStudentsTable;
