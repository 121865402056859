import { useState, useEffect, useContext } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore, auth } from "./FirebaseConfig";
import { UserContext } from "./UserContext";

const useFetchAllClasses = () => {
  console.log("The useFetchAllClasses Hook has fired!");
  const { user } = useContext(UserContext);
  const [classList, setClassList] = useState([]);

  useEffect(() => {
    const fetchAllClasses = async () => {
      try {
        if (user) {
          const teacherIdQuery = query(
            collection(firestore, "classes"),
            where("teacherId", "==", user.uid)
          );
          const coTeachersQuery = query(
            collection(firestore, "classes"),
            where("coTeachers", "array-contains", user.uid)
          );
          const otherTeachersQuery = query(
            collection(firestore, "classes"),
            where("otherTeachers", "array-contains", user.uid)
          );

          const [teacherIdSnapshot, coTeachersSnapshot, otherTeachersSnapshot] =
            await Promise.all([
              getDocs(teacherIdQuery),
              getDocs(coTeachersQuery),
              getDocs(otherTeachersQuery),
            ]);

          const classesData = [
            ...teacherIdSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })),
            ...coTeachersSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })),
            ...otherTeachersSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })),
          ];

          setClassList(classesData);
        }
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };
    console.log("FetchAllClasses Hook Classlist: ", classList);
    fetchAllClasses();
  }, [user]);

  return { classList };
};

export default useFetchAllClasses;
